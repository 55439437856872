<template>
  <canvas/>
</template>
<script>
import QRCode from 'qrcode';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  mounted() {
    QRCode.toCanvas(this.$el, this.value, (error) => {
      if (error) console.error(error);
    });
  },
};
</script>
